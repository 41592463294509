import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UiSetting {
  public UiSnackbarShowTimeInfo: number = 2;
  public UiSnackbarShowTimeWarning: number = 4;
  public UiSnackbarShowTimeError: number = 12;
  public UiSnackbarShowSaveMS: number = 400;

  constructor() {}
}
