import { Injectable } from "@angular/core";
import {
  CanActivate, Router,
} from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { ServiceWorkerUpdateService } from "src/app/shared/service-worker/update-service";
import { StateStore } from "src/app/shared/state/app.state-store";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  constructor(
    private msalService: MsalService,
    private state: StateStore,
    private router: Router,
    private serviceWorker: ServiceWorkerUpdateService
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    this.serviceWorker.initialize();
      if(!localStorage.getItem('username')){
        if(localStorage.getItem('isOnline') === 'true'){
          if(this.msalService.instance.getActiveAccount()){
              this.state.createUser();
          }
        }
        return true;
      } else {
        if(!this.state.initialized$.value){
          this.state.initialize();
        }
        return this.router.navigateByUrl("/location-description-list");
      }
  }
}