import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { MsalRedirectComponent } from "@azure/msal-angular";
import { BrowserUtils } from "@azure/msal-browser";
import { InitializeGuard } from "./account/shared/initialize.guard";
import { LoginGuard } from "./account/shared/login.guard";
import { IsOverwritedGuard } from "./account/shared/overwrite.guard";

const routes: Routes = [
  {
    path: "sign-in",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
      canActivate: [LoginGuard],
  },
  { path: "login", redirectTo: "sign-in", pathMatch: "full" },
  { path: "", redirectTo: "sign-in", pathMatch: "full" },
  { path: "sso-callback", component: MsalRedirectComponent },
  {
    path: "location-description-list",
    canActivate: [IsOverwritedGuard, InitializeGuard],
    loadChildren: () =>
      import(
        "./modules/location-description-list/location-description-list.module"
      ).then((m) => m.LocationDescriptionListModule),
  },
  {
    path: "location-description-create",
    canActivate: [InitializeGuard],
    loadChildren: () =>
      import(
        "./modules/location-description-create/location-description-create.module"
      ).then((m) => m.LocationDescriptionCreateModule),
  },
  {
    path: "overwrite",
    canActivate: [],
    loadChildren: () =>
      import(
        "./modules/overwrite/overwrite.module"
      ).then((m) => m.OverwriteModule),
  },
  {
    path: "inactive-user",
    canActivate: [],
    loadChildren: () =>
      import(
        "./modules/inactive-user/inactive-user.module"
      ).then((m) => m.InactiveUserModule),
  },
  {
    path: "admin",
    canActivate: [InitializeGuard],
    loadChildren: () =>
      import("./modules/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "location-description-update",
    canActivate: [InitializeGuard],
    loadChildren: () =>
      import(
        "./modules/location-description-update/location-description-update.module"
      ).then((m) => m.LocationDescriptionUpdateLayoutModule),
  },
  {
    path: "dev",
    canActivate: [InitializeGuard],
    loadChildren: () =>
      import("./modules/dev/dev.module").then((m) => m.ComponentsModule),
  },
  {
    path: "pages",
    canActivate: [],
    loadChildren: () =>
      import("./modules/pages/pages.module").then((m) => m.PagesModule),
  },
  { path: "**", redirectTo: "sign-in", pathMatch: "full" },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: PreloadAllModules,
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabled' : 'disabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
