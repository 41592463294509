import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { MsalService } from "@azure/msal-angular";
import { Logger } from "./shared/logger/logger";
import { ServiceWorkerUpdateService } from "./shared/service-worker/update-service";
import { StateStore } from "./shared/state/app.state-store";

@Component({
  selector: "app-root",
  templateUrl: "./app.container.html",
})
export class ContainerComponent {
  constructor(serviceWorkerUpdateService: ServiceWorkerUpdateService) {
    serviceWorkerUpdateService.initialize();
  }
}
