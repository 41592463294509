import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { ContainerComponent } from "./app.container";
import { SharedModule } from "./shared/shared.module";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpErrorInterceptor } from "./shared/http-interceptors/http-error.interceptor";
import { MessageService } from "./shared/services/message.service";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { DialogInputValueComponent } from "./shared/components/dialog-input-value/dialog-input-value.component";
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
  declarations: [ContainerComponent, DialogInputValueComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerImmediately",
    }),
    MsalModule.forRoot(new PublicClientApplication({
      auth: {
        clientId: environment.azureClientId, // Application (client) ID from the app registration
        authority: environment.azureAuthority, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
        redirectUri: environment.azureRedirectUri.replace("$BASE_URL$", location.hostname),// This is your redirect URI
        postLogoutRedirectUri: environment.azurePostLogoutRedirectUri.replace("$BASE_URL$", location.hostname),
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: [environment.azureScope]
      }
  }, {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      [environment.api.replace("$BASE_URL$", location.hostname),  [environment.azureScope]],
    ]),
  })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
      deps: [MessageService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [
    ContainerComponent
  ],
})
export class AppModule {}
