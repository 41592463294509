import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class IsOverwritedGuard implements CanActivate {
  constructor(
    private router: Router,
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if(this.router.url === '/overwrite' && this.router.getCurrentNavigation().extras.state?.overwrite !== 'leave'){
      return false;
    }
    return true;
  }
}