import { AppToolbarComponent } from "./components/app-toolbar/app-toolbar.component";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatModule } from "./mat/mat.module";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DialogYesNoComponent } from "./components/dialog-yes-no/dialog-yes-no.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FooterComponent } from "./footer/footer.component";
//
// Only the modules that are realy needed for al submodules, keep the footprint as small as posible
//
@NgModule({
  declarations: [AppToolbarComponent, DialogYesNoComponent, SpinnerComponent, FooterComponent],
  imports: [CommonModule, HttpClientModule, MatModule, RouterModule],
  exports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatModule,
    AppToolbarComponent,
    FooterComponent
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "fill" },
    },
  ],
})
export class SharedModule {}
